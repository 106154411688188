<template>
<div style="margin-left:auto; margin-right:auto;text-align;padding:8px">

                   <div class="qmajorheader text-center" v-html="langConv('Contact us')"></div>
                    <div class="qmediumboldfont" style="text-align:center;margin-top:10px">

                        <div style="text-align:left" v-html="langConv('Name')"></div>
                        <input  type="text" v-model="name" id="name" required placeholder="e.g. fred bloggs" />
                        <div style="text-align:left" v-html="langConv('Email')"></div>
                        <input  type="email" v-model="email" id="email" required placeholder="e.g. fred.blogs@gmail.com" />
                        <div style="text-align:left" v-html="langConv('Message')"></div>
                        <textarea  v-model="message" id="message" rows="5" required :placeholder="langConv('message')"></textarea>
                    
                        <div><a class="button" v-on:click="fbSetSave('feedbackmodal')" v-show="allowsend" v-html="langConv('Send')"></a></div>

                        <div class="cell small-12" v-if="errors.length">

                            <b> {{ langConv('Please correct the following error(s)') }}:</b>
                            <div v-for="(error,i) in errors" style="color:red" :key="i">{{ error }}</div>
                        </div>
                        <div v-show="!allowsend">
                            <vue-recaptcha sitekey="6LdwOq0UAAAAALJznZE_8pCUXvUcjA8QEnZSJVrB" size="500" :loadRecaptchaScript="true" @verify="onVerify" @expired="onExpire"></vue-recaptcha>
                        </div>
                    </div>

    </div>
        


</template>

<script>

    import axios from 'axios'
//import { relativeTimeThreshold } from 'moment';
    import VueRecaptcha from 'vue-recaptcha';
//import VueRouter from 'vue-router';

    export default {
        data: function () {
            return {

                name: null,
                email: null,
                message: null,
                allowsend: false,
                errors:[]
            }
        },
        components: {
            VueRecaptcha
        },
        methods: {
              fbSetSave: function (val) {

                this.checkForm();

                //create message
                var contactobj = { data: { name: this.name, email: this.email, message: this.message } };

                axios.post(process.env.VUE_APP_WEBAPI  + "SaveRetrieveandDeleteData.svc/SaveContactForm",
                    {
                        ContactJSON: JSON.stringify(contactobj)
                    }

                ).then(response => {
                   
                    if (response.status == 200) {
                        alert("Message Sent");
                        this.$router.push('/');
                    }

                    else {
                        alert("Error Sending Message");
                   }
                   //this.hidePopUp(val);
                });
            },
            onVerify: function (response) {
                this.allowsend = true;
            },
            onExpire: function (response) {
                this.allowsend = false;
            },
            checkForm: function (e) {
                this.errors = [];

                if (!this.name) {
                    this.errors.push("Name required.");
                }
                if (!this.email) {
                    this.errors.push('Email required.');
                } else if (!this.validEmail(this.email)) {
                    this.errors.push('Valid email required.');
                }
                if (!this.message) {
                    this.errors.push("Message required.");
                }

                if (!this.errors.length) {
                    return true;
                }

                e.preventDefault();
            },
            validEmail: function (email) {
                var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }
        }        
    }
</script>

<style scoped>

[type=text],[type=email],textarea {
    font-weight:600 !important;
    font-size:13px;
}

</style>